import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Icon from '../Icon'
import Locations from './Locations'
import Button from '../Button'
import Transactions from '../../components/Transactions'
import { Divider, IconButton, TextField } from '@mui/material'
import {
  Close,
  AccessTime,
  Comment,
  People,
  DeleteForever,
  ArrowRightAltRounded,
  AttachMoney,
  CheckCircleOutline,
  Forum,
  Restore,
  Info,
  ReceiptLong,
  CalendarMonth
} from '@mui/icons-material'
import Alert from '../Alert'
import UserInfo from '../UserInfo'
import SendComment from './SendComment'
import MatchesCompanions from './MatchesCompanions'
import CancelLobby from './CancelLobby'
import UncancelLobby from './UncancelLobby'
import CompleteLobby from './CompleteLobby'
import DeleteCompanion from './DeleteCompanion'
import Messages from '../../components/Messages'

const dias = ['Domingo ', 'Lunes ', 'Martes ', 'Miercoles ', 'Jueves ', 'Viernes ', 'Sabado ']
const meses = [
  'Enero ',
  'Febrero ',
  'Marzo ',
  'Abril ',
  'Mayo ',
  'Junio ',
  'Julio ',
  'Agosto ',
  'Septiembre',
  'Octubre ',
  'Noviembre ',
  'Diciembre '
]

const LobbyInfo = ({ lobby, setSelected }) => {
  const [adminComment, setAdminComment] = useState(lobby?.Admin?.comment)
  const [userAlert, setUserAlert] = useState(false)
  const [cancelAlert, setCancelAlert] = useState(false)
  const [cancelLobby, setCancelLobby] = useState(false)
  const [uncancelLobby, setUncancelLobby] = useState(false)
  const [completeAlert, setCompleteAlert] = useState(false)
  const [completeLobby, setCompleteLobby] = useState(false)
  const [visible, setVisible] = useState(Boolean(lobby))
  const [sendComment, setSendComment] = useState(false)
  const [openChat, setOpenChat] = useState(false)
  const [openTransactions, setOpenTransactions] = useState(false)
  const [userType, setUserType] = useState(false)
  const [deleteCompanion, setDeleteCompanion] = useState(false)
  const [deleteCompanionAlert, setDeleteCompanionAlert] = useState(false)
  const [searchMatchesCompanions, setSearchMatchesCompanions] = useState(false)

  let occupiedSeats = 0
  if (lobby.companions) {
    occupiedSeats = sumarArray(lobby.companions.map(x => x.seats))
  }

  const departureTime = lobby.Journey.departure_time.substr(
    lobby.Journey.departure_time.indexOf('T') + 1,
    5
  )
  const departureDateTimeCreated = new Date(new Date(lobby.created).getTime() - 3 * 60 * 60 * 1000)
  const departureTimeCreated = departureDateTimeCreated
    .toISOString()
    .substr(departureDateTimeCreated.toISOString().indexOf('T') + 1, 5)
  const departureDateCreated =
    dias[new Date(lobby.created).getDay()] +
    new Date(lobby.created).getDate() +
    ' de ' +
    meses[new Date(lobby.created).getMonth()]
  const departureDatetimeToShow = new Date(
    new Date(lobby.Journey.departure_time).getTime() + 3 * 60 * 60 * 1000
  ) //porqye si no en e lobby info muestra mal el dia en el caso de que sea la hora entra 00 - 03 AM
  const lastChangeDateTimeCreated = new Date(new Date(lobby.updated).getTime() - 3 * 60 * 60 * 1000)
  const lastChangeTimeCreated = lastChangeDateTimeCreated
    .toISOString()
    .substr(lastChangeDateTimeCreated.toISOString().indexOf('T') + 1, 5)
  const lastChangeDateCreated =
    dias[new Date(lobby.updated).getDay()] +
    new Date(lobby.updated).getDate() +
    ' de ' +
    meses[new Date(lobby.updated).getMonth()]
  let cancelledDateTime
  let cancelledTime
  let cancelledDate
  if (lobby.cancelled && lobby.cancelled_time) {
    cancelledDateTime = new Date(new Date(lobby.cancelled_time).getTime() - 3 * 60 * 60 * 1000)
    cancelledTime = cancelledDateTime
      .toISOString()
      .substr(cancelledDateTime.toISOString().indexOf('T') + 1, 5)
    cancelledDate =
      dias[new Date(lobby.cancelled_time).getDay()] +
      new Date(lobby.cancelled_time).getDate() +
      ' de ' +
      meses[new Date(lobby.cancelled_time).getMonth()]
  }

  let arrivalTime = lobby.Journey.estimated_time.substr(
    lobby.Journey.estimated_time.indexOf('T') + 1,
    5
  )
  const departureDate =
    dias[departureDatetimeToShow.getDay()] +
    departureDatetimeToShow.getDate() +
    ' de ' +
    meses[departureDatetimeToShow.getMonth()]
  const today = new Date()
  const late =
    new Date(lobby.Journey.estimated_time).getTime() + 3 * 60 * 60 * 1000 - today.getTime() < 0
  const cancelled = lobby.cancelled

  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingLeft: '1.6rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }

  const handleClose = () => {
    setVisible(false)
    setSelected({})
  }

  const handleDelete = () => {
    setCancelAlert(true)
  }

  const handleComplete = () => {
    setCompleteAlert(true)
  }

  const handleOpenChat = () => {
    setOpenChat(true)
  }

  const handleOpenTransactions = () => {
    setOpenTransactions(true)
  }

  const handleUncancel = () => {
    setUncancelLobby(true)
  }

  return (
    <>
      <Modal
        isOpen={visible}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='w-full justify-end flex gap-2'>
          <IconButton onClick={handleOpenChat} style={{ paddingTop: '0' }}>
            <Forum />
          </IconButton>
          <IconButton onClick={handleOpenTransactions} style={{ paddingTop: '0' }}>
            <ReceiptLong />
          </IconButton>
          {!lobby.completed && late && (
            <IconButton onClick={handleComplete} style={{ paddingTop: '0' }}>
              <CheckCircleOutline />
            </IconButton>
          )}
          {!lobby.cancelled && (
            <IconButton onClick={handleDelete} style={{ paddingTop: '0' }}>
              <DeleteForever />
            </IconButton>
          )}
          {lobby.cancelled && (
            <IconButton onClick={handleUncancel} style={{ paddingTop: '0' }}>
              <Restore />
            </IconButton>
          )}
          <IconButton onClick={handleClose} style={{ paddingTop: '0' }}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className='h-4/5 overflow-auto'>
          <div className='w-full flex flex-col items-center text-2xl'>
            {/* <img
              className='rounded-full mt-3'
              src={lobby.Owner_info.picture}
              width='95px'
              height='95px'
            /> */}
            <a
              onClick={() => {
                setUserAlert(lobby.Owner_info.id)
                setUserType('owner')
              }}
              className='mt-3 mb-3 text-blue-600 cursor-pointer'
            >
              {lobby.Owner_info.first_name}
            </a>
            <TextField
              multiline
              label='Comentario de administrador'
              value={adminComment}
              onChange={evt => setAdminComment(evt.target.value)}
            />
            <Button
              name={'Buscar coincidencias'}
              style={{
                marginTop: '15px',
                marginBottom: '15px',
                zIndex: 300,
                fontSize: '0.8rem'
              }}
              onClick={() => setSearchMatchesCompanions(true)}
              removeArrow={true}
            />
            {lobby.comment ? (
              <div className='flex gap-2 text-base mt-5 mb-4'>
                <Comment style={{ color: '#c65944' }} fontSize='small' />
                <div style={{ color: '#c65944' }} className='flex flex-row mr-3 mt-1'>
                  {lobby.comment}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='w-full flex flex-col mt-1 text-2xl gap-4 sm:items-center'>
            <Locations
              originName={lobby.Journey.origin_name.formatted_address}
              destinationName={lobby.Journey.destination_name.formatted_address}
            />
            <div className='flex gap-3 text-base'>
              <AccessTime style={{ color: '#c65944' }} fontSize='small' />
              <div classname='flex flex-row'>
                <div>{departureDate}</div>
                <div>{`Salida: ${departureTime}`}</div>
                <div>{`Llegada: ${arrivalTime} `}</div>
              </div>
            </div>
            <div className='flex gap-2 text-base'>
              <People style={{ color: '#c65944' }} fontSize='small' />
              <div className='flex flex-col justify-center'>
                {lobby.seats_available - occupiedSeats + ' asientos disponibles'}
              </div>
            </div>
            <div className='flex gap-2 text-base'>
              <p className='text-primary'>$U</p>
              <div className='flex flex-row'>
                {lobby.price} ({lobby.price_without_fee})
              </div>
            </div>
            {late ? <p className='text-blue-600'>TERMINADO</p> : <></>}
            {cancelled ? <p className='text-red-500'>CANCELADO</p> : <></>}
            {lobby.cancelled_time && (
              <div className='text-base'>
                Cancelado el: {cancelledDate} {cancelledTime}
              </div>
            )}
            {lobby.cancelled_reason && (
              <div className='text-base'>Razón: {lobby.cancelled_reason}</div>
            )}
            {lobby.changes.length != 0 ? (
              <>
                <div className='text-base'>
                  Último cambio: {lastChangeDateCreated} {lastChangeTimeCreated}
                </div>
                <div className='flex flex-col justify-center bg-gray-100 rounded-lg p-5 pt-3'>
                  <div className='flex justify-center text-2xl color-gray-600 mb-3'>
                    Datos anteriores cambiados por el conductor
                  </div>
                  <div className='flex flex-col justify-center gap-3'>
                    {lobby.changes.map(change => (
                      <Change change={change} occupiedSeats={occupiedSeats} />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {lobby.companions.length >= 1 ? (
              <div className='bg-gray-100 rounded-lg p-5'>
                {lobby.companions.map(companion => (
                  <div>
                    <div className='w-full flex mt-4 text-2xl gap-4 sm:items-center'>
                      <div className='flex gap-2 text-base'>
                        {/* <People style={{ color: '#c65944' }} fontSize='middle' /> */}
                        <div
                          className='flex flex-col justify-center text-blue-600 cursor-pointer'
                          onClick={() => {
                            setUserAlert(companion.user_id)
                            setUserType('lobby-companion')
                          }}
                        >
                          {companion.user_id}
                        </div>
                      </div>
                      <div className='flex gap-2 text-base'>-</div>
                      <div className='flex gap-2 text-base'>
                        <People style={{ color: '#c65944' }} fontSize='middle' />
                        <div className='flex flex-col justify-center'>{companion.seats}</div>
                      </div>
                      <div className='flex gap-2 text-base'>
                        <IconButton
                          onClick={() => setDeleteCompanionAlert(companion.user_id)}
                          style={{ paddingTop: '0' }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </div>
                    </div>
                    <div className='flex gap-14 text-lg sm:items-center text-gray-400'>
                      <CompanionTrip
                        companion={companion}
                        stops={lobby.Journey.stops}
                        lobby={lobby}
                      />
                      <div>
                        {companion.created && (
                          <>
                            <CalendarMonth fontSize='medium' />
                            <>{new Date(companion.created).toLocaleString()}</>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='flex gap-14 text-lg sm:items-center text-gray-400'>
                      <div>
                        <AttachMoney fontSize='medium' />
                        {companion.fee ? (
                          <>
                            {companion.price + companion.fee} ({companion.price})
                          </>
                        ) : (
                          <>{companion.price} ()</>
                        )}
                      </div>
                      <div>
                        {companion.fee && (
                          <>
                            <Info fontSize='medium' />
                            {companion.transaction_id}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div></div>
            )}
            <div className='text-base'>
              Agendado: {departureDateCreated} {departureTimeCreated}
            </div>
            <div className='text-base'>ID: {lobby._id}</div>
            <div className='text-base mb-3'>
              Stops: {lobby.Journey.stops?.map(x => x.location_name).toString()}
            </div>
            <div className='text-base mb-3'>Seats Remaining: {lobby.seats_remaining}</div>
            {lobby.payment && <div className='text-base mb-3'>Medio de Pago: {lobby.payment}</div>}
          </div>
        </div>
        <div className='flex flex-col'>
          <Divider />
          <Button
            name={'Enviar comentario'}
            style={{ marginTop: '10px', zIndex: 300, fontSize: '0.8rem' }}
            onClick={() => setSendComment(true)}
          />
          <Alert
            hideButton
            visible={userAlert}
            onClose={() => setUserAlert(false)}
            style={{ height: 510 }}
          >
            <UserInfo userId={userAlert} lobby={lobby} type={userType} />
          </Alert>
          <Alert
            hideButton
            visible={cancelAlert}
            onSubmit={() => {
              setCancelLobby(true)
              setCancelAlert(false)
            }}
            onClose={() => setCancelAlert(false)}
          >
            <div className='flex flex-col justify-center'>
              <div className='text-base'>¿Estás seguro de que quieres cancelar esta lobby?</div>
              <div className='text-base text-green-400'>
                Todos los acompañantes recibirán un reembolso total
              </div>
            </div>
          </Alert>
          <Alert
            hideButton
            visible={completeAlert}
            onSubmit={() => {
              setCompleteLobby(true)
              setCompleteAlert(false)
            }}
            onClose={() => setCompleteAlert(false)}
          >
            <div className='flex flex-col justify-center'>
              <div className='text-base'>
                ¿Estás seguro de que quieres marcar como completada esta lobby?
              </div>
            </div>
          </Alert>
          <Alert
            hideButton
            visible={deleteCompanionAlert}
            onSubmit={() => {
              setDeleteCompanion(deleteCompanionAlert)
              setDeleteCompanionAlert(false)
            }}
            onClose={() => setDeleteCompanionAlert(false)}
          >
            <div className='flex flex-col justify-center'>
              <div className='text-base'>
                ¿Estás seguro de que quieres eliminar este acompañante?
              </div>
              <div className='text-base text-green-400'>
                El acompañante recibirá un reembolso total
              </div>
            </div>
          </Alert>
          <Alert
            hideButton
            visible={openTransactions}
            onClose={() => setOpenTransactions(false)}
            style={{ height: 510 }}
          >
            <Transactions lobbyId={String(lobby._id)} />
          </Alert>
          {sendComment && (
            <SendComment lobby={lobby} comment={adminComment} setSendComment={setSendComment} />
          )}
          {searchMatchesCompanions && (
            <MatchesCompanions lobby={lobby} setMatchesCompanions={setSearchMatchesCompanions} />
          )}
          {cancelLobby && <CancelLobby lobby={lobby} />}
          {uncancelLobby && <UncancelLobby lobby={lobby} />}
          {completeLobby && <CompleteLobby lobby={lobby} />}
          {deleteCompanion && <DeleteCompanion lobby={lobby} companionId={deleteCompanion} />}
        </div>
      </Modal>
      {openChat && (
        <Messages
          visible={openChat}
          setVisible={setOpenChat}
          initialLobbyId={lobby._old_id ?? lobby._id}
        />
      )}
    </>
  )
}

const Change = ({ change, occupiedSeats }) => {
  const departureDatetimeToShow = new Date(
    new Date(change.departure_time).getTime() + 3 * 60 * 60 * 1000
  )
  const departureDateTime =
    dias[departureDatetimeToShow.getDay()] +
    departureDatetimeToShow.getDate() +
    '/' +
    departureDatetimeToShow.getMonth() +
    ' ' +
    departureDatetimeToShow.getHours() +
    ':' +
    departureDatetimeToShow.getMinutes()
  const changeDatetimeToShow = new Date(new Date(change.updated).getTime())
  const changeDate =
    String(changeDatetimeToShow.getDate()).padStart(2, '0') +
    '/' +
    String(changeDatetimeToShow.getMonth()).padStart(2, '0')
  //get changetime like hh:mm with leading zeros
  const changeTime =
    changeDatetimeToShow.getHours().toString().padStart(2, '0') +
    ':' +
    changeDatetimeToShow.getMinutes().toString().padStart(2, '0')

  return (
    <div className='flex flex-col border-2 p-4 gap-2'>
      <div className='flex gap-3 text-base'>
        <div className='flex gap-1 text-base'>
          <People style={{ color: '#c65944' }} fontSize='large' />
          <div className='flex flex-col justify-center'>
            {change.seats_available - occupiedSeats + ' asientos disponibles'}
          </div>
        </div>
        <div className='flex gap-1 text-base'>
          <AccessTime style={{ color: '#c65944' }} fontSize='large' />
          <div className='flex flex-col justify-center'>Salida: {departureDateTime}</div>
        </div>
      </div>
      <div className='flex gap-3 text-base'>
        <div className='w-full flex gap-2 text-1xl'>
          <Icon img='peso.png' height={12} width={28} />
          <div className='flex flex-row'>{change.price}</div>
        </div>
        <div className='w-full flex gap-2 text-lg text-gray-400'>
          <div className='w-full flex justify-end'>
            {changeDate} a las {changeTime}
          </div>
        </div>
      </div>
    </div>
  )
}

const CompanionTrip = ({ companion, stops, lobby }) => {
  const [origin, setOrigin] = useState(lobby.Journey.origin_name.locality)
  const [destination, setDestination] = useState(lobby.Journey.destination_name.locality)

  useEffect(() => {
    stops?.forEach(stop => {
      if (
        JSON.stringify(companion.start.coordinates) == JSON.stringify(stop.location.coordinates)
      ) {
        setOrigin(stop.location_name)
      }
      if (JSON.stringify(companion.end.coordinates) == JSON.stringify(stop.location.coordinates)) {
        setDestination(stop.location_name)
      }
    })
  }, [])

  return (
    <div className='flex gap-4 text-lg sm:items-center text-gray-400'>
      {origin} <ArrowRightAltRounded fontSize='large' /> {destination}
    </div>
  )
}

const sumarArray = arr => {
  let sum = 0
  for (let i = 0; i < arr.length; i += 1) {
    sum += arr[i]
  }
  return sum
}

export default LobbyInfo
