import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { modalStyles } from './styles'
import { Input, MenuItem, Select, TextField } from '@mui/material'
import LoadingIndicator from '../../common/LoadingIndicator'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'

export default () => {
  const { loading, data } = useFetch(`${urlApi}/admin/coupons`)
  const [activeCoupons, setActiveCoupons] = useState([])
  const [inactiveCoupons, setInactiveCoupons] = useState([])
  const [createModal, setCreateModal] = useState(false)
  const [tag, setTag] = useState('all')

  useEffect(() => {
    if (!loading && data?.length > 0) {
      setActiveCoupons(data.filter(coupon => !coupon.disabled))
      setInactiveCoupons(data.filter(coupon => coupon.disabled))
    }
  }, [loading, data])

  useEffect(() => {
    if (tag) {
      if (tag === 'all') {
        setActiveCoupons(data.filter(coupon => !coupon.disabled))
        setInactiveCoupons(data.filter(coupon => coupon.disabled))
      } else {
        setActiveCoupons(data.filter(coupon => !coupon.disabled && coupon.tag === tag))
        setInactiveCoupons(data.filter(coupon => coupon.disabled && coupon.tag === tag))
      }
    }
  }, [tag])

  return (
    <>
      {loading ? (
        <div className='w-full h-screen flex items-center justify-center'>
          <LoadingIndicator />
        </div>
      ) : (
        <div className='flex flex-row justify-between w-full h-full p-10'>
          <div className='h-[88vh] w-1/2 flex flex-col items-center overflow-auto px-3'>
            <div className='flex gap-6 mb-4 items-center'>
              <p className='text-xl font-bold'>Cupones activos</p>
              <p
                className='text-lg text-light_coral cursor-pointer'
                onClick={() => setCreateModal(true)}
              >
                Crear cupón
              </p>
              <Tag filter tag={tag} setTag={setTag} />
            </div>
            {activeCoupons.length === 0 ? (
              <p>No hay cupones activos</p>
            ) : (
              activeCoupons.map(coupon => <CouponCard coupon={coupon} />)
            )}
          </div>
          <div className='h-[90vh] w-1/2 flex flex-col items-center overflow-auto px-3'>
            <p className='text-xl font-bold mb-4'>Chats desactivados</p>
            {inactiveCoupons.length === 0 ? (
              <p>No hay cupones desactivados</p>
            ) : (
              inactiveCoupons.map(coupon => <CouponCard coupon={coupon} />)
            )}
          </div>
        </div>
      )}
      {createModal && (
        <CreateModal setActiveCoupons={setActiveCoupons} setCreateModal={setCreateModal} />
      )}
    </>
  )
}

const CouponCard = ({ coupon }) => {
  const [active, setActive] = useState(!coupon.disabled)
  const [update, setUpdate] = useState(false)
  const date = new Date(coupon.created).toLocaleDateString()

  const handleToggle = () => {
    if (window.confirm('¿Activar/Desactivar cupón?')) {
      setUpdate(true)
    }
  }

  return (
    <div className='w-full rounded-[12px] flex flex-col p-6 bg-white shadow gap-8 mb-3'>
      <div className='flex justify-between items-center w-full'>
        <p className='max-w-2/3 font-bold text-lg'>{coupon.code}</p>
        <p className='font-bold'>{date}</p>
      </div>
      <div className='flex flex-row w-full justify-between'>
        <div className='flex flex-col items-center text-lg'>
          <p className='font-bold'>Descuento</p>
          <p>{coupon.discount}%</p>
        </div>
        <div className='flex flex-col items-center text-lg'>
          <p className='font-bold'>Usado</p>
          <p>{coupon.used}</p>
        </div>
      </div>
      <div className='w-full flex items-end justify-between'>
        <p className='text-lg font-bold'>{tagToLabel(coupon.tag)}</p>
        {update ? (
          <Update id={coupon._id} active={active} setUpdate={setUpdate} setActive={setActive} />
        ) : (
          <ToggleSwitch checked={active} handleToggle={handleToggle} />
        )}
      </div>
    </div>
  )
}

const ToggleSwitch = ({ checked, handleToggle }) => {
  return (
    <label className={'relative inline-flex items-center cursor-pointer'}>
      <div
        onClick={handleToggle}
        className={`ring-0 rounded-full ${
          checked ? 'bg-light_coral after:bg-[#fff] after:translate-x-[19px]' : 'bg-[#E8E8E8]'
        }  after:bg-[#ABABAB] outline-none duration-300 after:duration-300 w-[50px] h-[30px] shadow-md peer-focus:outline-none after:rounded-full after:absolute after:outline-none after:h-[20px] after:w-[20px] after:top-[5px] after:left-[6px] after:flex after:justify-center after:items-center peer-hover:after:scale-95 after:content-[""]`}
      />
    </label>
  )
}

const CreateModal = ({ setActiveCoupons, setCreateModal }) => {
  const [code, setCode] = useState('')
  const [discount, setDiscount] = useState()
  const [tag, setTag] = useState('compensation')
  const [reusable, setReusable] = useState(false)
  const [userId, setUserId] = useState('')
  const [whitelist, setWhitelist] = useState('')
  const [create, setCreate] = useState(false)

  const handleClose = () => {
    setCreateModal(false)
  }

  const handleSuccess = data => {
    setActiveCoupons(prev => [data, ...prev])
    setCreate(false)
    setCreateModal(false)
  }

  const handleError = () => {
    alert('Hubo un error')
    setCreate(false)
  }

  return (
    <Modal style={modalStyles} isOpen={true} onRequestClose={handleClose}>
      <div className='flex flex-col justify-between h-full'>
        <p className='text-xl font-bold'>Crear cupón</p>
        <TextField
          size='small'
          label='Código'
          value={code}
          inputProps={{ autoCapitalize: 'characters' }}
          onChange={evt => setCode(evt.target.value)}
        />
        <TextField
          size='small'
          type='number'
          label='Descuento (porcentaje)'
          value={discount}
          onChange={evt => setDiscount(evt.target.value)}
        />
        <Tag tag={tag} setTag={setTag} />
        {tag === 'compensation' ? (
          <TextField
            size='small'
            label='Usuario (Id)'
            value={userId}
            onChange={evt => setUserId(evt.target.value)}
          />
        ) : (
          <TextField
            size='small'
            label='Whitelist (Cohort Id)'
            value={whitelist}
            onChange={evt => setWhitelist(evt.target.value)}
          />
        )}
        <div className='flex flex-row gap-2 items-center'>
          <ToggleSwitch checked={reusable} handleToggle={() => setReusable(!reusable)} />
          <p>Reusable</p>
        </div>
        {create ? (
          <div className='flex w-full items-center justify-center'>
            <Create
              body={{ code, discount, tag, reusable, userId, whitelist }}
              handleSuccess={handleSuccess}
              handleError={handleError}
            />
          </div>
        ) : (
          <button
            className={`bg-light_coral text-white rounded-lg p-2 ${!code && 'bg-gray'}`}
            onClick={() => setCreate(true)}
            disabled={!code || !discount}
          >
            Crear
          </button>
        )}
      </div>
    </Modal>
  )
}

const Create = ({ body, handleSuccess, handleError }) => {
  const { loading, data, responseOk } = useFetch(`${urlApi}/admin/coupons`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...body,
      code: body.code.trim(),
      whitelist: body.whitelist.trim().length > 0 ? body.whitelist : null,
      user_id: body.userId.trim().length > 0 ? body.userId : null
    })
  })

  useEffect(() => {
    if (responseOk && !Array.isArray(data)) {
      handleSuccess(data)
    } else if (!loading && !responseOk) {
      handleError()
    }
  }, [loading, responseOk, data])

  return <LoadingIndicator />
}

const Update = ({ id, active, setUpdate, setActive }) => {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/coupons/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      disabled: active
    })
  })

  useEffect(() => {
    if (responseOk) {
      setActive(!active)
      setUpdate(false)
    } else if (!loading) {
      alert('Hubo un error')
      setUpdate(false)
    }
  }, [loading, responseOk])

  return <LoadingIndicator />
}

const Tag = ({ tag, setTag, filter }) => {
  return (
    <Select value={tag} size='small' onChange={evt => setTag(evt.target.value)}>
      {filter && <MenuItem value={'all'}>Todos</MenuItem>}
      <MenuItem value={'compensation'}>Compensación</MenuItem>
      <MenuItem value={'promotion'}>Promoción</MenuItem>
      <MenuItem value={'alliance'}>Alianza</MenuItem>
      <MenuItem value={'influencer'}>Influencer</MenuItem>
      <MenuItem value={'other'}>Otro</MenuItem>
    </Select>
  )
}

const tagToLabel = tag => {
  switch (tag) {
    case 'promotion':
      return 'Promoción'
    case 'compensation':
      return 'Compensación'
    case 'alliance':
      return 'Alianza'
    case 'influencer':
      return 'Influencer'
    case 'other':
      return 'Otro'
    default:
      return 'Ninguno'
  }
}
