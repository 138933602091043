import { useAuth0 } from '@auth0/auth0-react'

const Menu = ({ children, setMessages, isMobile }) => {
  const { logout } = useAuth0()
  return (
    <div className='mt-4 px-4'>
      <div className='flex flex-row gap-4 items-center'>
        <div
          className='bg-[#d65944] w-12 h-10 rounded-lg
              flex justify-center items-center cursor-pointer'
          onClick={() => setMessages(true)}
        >
          CHAT
        </div>
        {children}
        {!isMobile && (
          <div
            className='bg-gray-400 w-32 h-10 rounded-lg
              flex justify-center items-center cursor-pointer'
            onClick={() => logout({ returnTo: window.origin })}
          >
            Log out
          </div>
        )}
      </div>
    </div>
  )
}

export default Menu
